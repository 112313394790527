import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import './stylesheets/HomeJobs.css'
import ProfileImg from '../../pages/stylesheets/imgs/ProfileImg.png'
import { useState } from "react";

export const HomeJobs = ({ id, title, description, payment, owner, loading }) => {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    return (
        <div id="homeJobsTab">
            {loading ? <Loading /> :
                !id ? <></> :
                    <>
                        <div id="homeJobsTitle">
                            <img id="homeJobsTitleImg" src={owner?.imageURL || require('../../pages/stylesheets/imgs/ProfileImg.png')} />
                            <div id="homeJobsTop">
                                <h4 id="homeJobsTitleText">{title}</h4>
                                <h5 id="homeJobsOwnerName">{owner?.firstName} {owner?.lastName}</h5>
                            </div>
                        </div>
                        <div id="homeJobsDescription">
                            <h4 id="homeJobsDescriptionText">{description.length > 150 && windowWidth > 1024 ? description.substring(0, 150) + '...' : description.substring(0, 75) + '...' }</h4>
                        </div>
                        <div id="homeJobsEnding">
                            <h4 id="homeJobsPayment">{payment}</h4>
                            <button id="homeJobsApplyButton" onClick={() => {
                                navigate(`/ApplyForas/${id}`);
                                // refresh
                                navigate(0);
                            }}>Apply Now</button>
                        </div>
                    </>}
        </div>
    );
};
