import React from 'react';
import './stylesheets/AlertModal.css';

/**
 * 
 * @param {{show: boolean, 
 * text: string, 
 * widthController: number, 
 * alertText: string, 
 * buttonsVisible: boolean, 
 * b1: {text: string, onClick: React.MouseEventHandler<HTMLButtonElement>}, 
 * b2: {text: string, onClick: React.MouseEventHandler<HTMLButtonElement>}, 
 * b3: {text: string, onClick: React.MouseEventHandler<HTMLButtonElement>}}} param0 
 * @returns 
 */
export default function AlertModal({ show, text, widthController, alertText, buttonsVisible, b1, b2, b3, b2bg, b3bg, smallalert = false}) {
  if (!show) {
    return null;
  }

  const buttonDisplayStyle = buttonsVisible ? 'inline-block' : 'none';
  const firstButtonDisplayStyle = b1 ? buttonDisplayStyle : 'none';
  const secondButtonDisplayStyle = b2 ? buttonDisplayStyle : 'none';
  const thirdButtonDisplayStyle = b3 ? buttonDisplayStyle : 'none';

  return (
    <div className="modal-overlay" style={{zIndex:9999}}>
      <div className="modal">
        <div className="modal-content">
          <p>{text}</p>
          {/* Preferred Use for Yes and No or Confirm and Cancel  */}
          <button
            id="ModalButton"
            style={{ width: `${widthController}%`, display: firstButtonDisplayStyle }}
            onClick={b1?.onClick}
          >
            {b1?.text}
          </button>
          <button
            id="ModalButton"
            style={{ width: `${widthController}%`, display: secondButtonDisplayStyle, backgroundColor: b2bg }}
            onClick={b2?.onClick}
          >
            {b2?.text}
          </button>
          <p style={{fontSize: smallalert? 10: '', lineHeight: smallalert ? 1.25: ''}}>{alertText}</p>
          {/* Close Button for when you are using the Alert Text  */}
          <button
            id="ModalButton"
            style={{ width: `${widthController}%`, display: thirdButtonDisplayStyle, backgroundColor: b3bg }}
            onClick={b3?.onClick}
          >
            {b3?.text}
          </button>

        </div>
      </div>
    </div>
  );
}
