import React, { useCallback, useEffect, useState } from 'react';
import './stylesheets/navbar.css';
import SmallLogo from './imgs/small_logo.png';
import { FaBell, FaEllipsisH, FaFacebook, FaRegBell, FaTimes, FaDoorOpen, FaBorderStyle } from 'react-icons/fa';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import JohnCena from './pages/stylesheets/imgs/jocena.jpg'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Loading from './pages/components/Loading'
import { logout } from './controllers/AuthController';
import { useUser } from './hooks/useUser';
import { useChatSocketEvents } from './hooks/useChatSocketEvents';
import { chatSocket } from '.';
import { chatDropdownLimit } from './config';
import ProfileImage from './pages/components/ProfileImage';
import useChats from './hooks/useChats';
import { formatText } from './utils/TextUtils.js';

const GetNotify = ({ notif }) => {

  const navigate = useNavigate()
  const navToNotif = useCallback(() => {
    navigate(`/ApplyForas/${notif.doc.split('#')[0]}`)
    window.location.reload()
  }, [notif])
  // return (
  //   <div
  //     style={{
  //       width: 400,
  //       backgroundColor: '#f9f9f9',
  //       margin: 0,
  //       borderStyle: 'solid',
  //       borderColor: '#34AED4',
  //       borderWidth: 1,
  //     }}

  //     onClick={navToNotif}
  //   >
  //     <h4 style={{ margin: 0, marginLeft: '5%', paddingTop: '3%' }}>
  //       {notif.title}
  //     </h4>
  //     <h5
  //       style={{
  //         margin: 2,
  //         marginLeft: '7.5%',
  //         fontSize: 12,
  //         paddingBottom: '2%'
  //       }}
  //     >
  //       {notif.body}
  //     </h5>
  //   </div>
  // );
  return (
    <div onClick={navToNotif} className='NotifyWrap'>
      <h4 style={{ padding: 0, margin: 0, width: '100%', fontSize: 14 }}>
        {notif.title}
      </h4>
      <h5 style={{ padding: 0, margin: 5, width: '100%', fontSize: 12}}>{formatText(notif.body)}</h5>
      <div id='BottomNotifyWrap'>
        <h5 id='DateNotification'>{new Date(notif.updatedAt).toLocaleString('en-eg',
          {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
          })}</h5>
      </div>
    </div>
  )
};

const GetMessage = ({ chat, curUserId }) => {

  var curMeta, otherMeta;
  for (let meta of chat.userMeta) {
    if (meta.user === curUserId) {
      curMeta = meta
    }
    else {
      otherMeta = meta
    }
  }

  const name = otherMeta.firstName + ' ' + otherMeta.lastName
  const messageType = chat.latestMessage.content.contentType
  const message = messageType === 'text' ? chat.latestMessage.content.data : 'Document'

  return (
    <Link to={`/ChatPage?id=${otherMeta.user}`}>
      <div
        style={{
          width: '97.5%',
          backgroundColor: '#ffffff50',
          margin: 0,
          // borderStyle: 'solid',
          // borderColor: '#34AED4',
          marginLeft: '1.25%',
          borderRadius: 10
        }}
      >
        <div style={{ display: 'flex', backgroundColor: 'f9f9f9', textDecoration: 'none' }}>
          <div style={{ width: 50, height: 50, alignSelf: 'center', borderRadius: 25 }}>
            <ProfileImage id={otherMeta.user} size={50} />
          </div>
          <div style={{ marginLeft: '2.5%' }}>
            <h4 style={{ marginBottom: 0, textDecoration: 'none', color: 'black', marginTop: 15 }}>{name}</h4>
            <h5
              style={{
                fontSize: 12, textDecoration: 'none', color: '#1e1e1e90', marginTop: 10, fontStyle: 'italic', marginLeft: 10, marginRight: 10
              }}
            > {chat.latestMessage.sender === curMeta.user ? "You" : otherMeta.firstName}: {message}</h5>
          </div>
        </div>

      </div>
    </Link>

  );
};
export default function Navbar() {
  const [showNotifications, setShowNotifications] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [notifList, setNotifList] = useState()
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [chatError, setChatError] = useState()
  const location = useLocation();
  const searchParams = useSearchParams()[0]
  const navigate = useNavigate();
  const { loggedIn, id: curUserId } = useUser();
  const [userMeta, setUserMeta] = useState()
  const chats = useChats()
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  // const [selectedOption, setSelectedOption] = useState(null);

  const events = [
    {
      name: 'newChats',
      handler: function onNewChats(res) {
        if (!res.unreadOnly) {
          return;
        }

        if (res.chats.length > chatDropdownLimit) {
          res.chats = res.chats.slice(0, chatDropdownLimit)
        }
        chats.addChats(res.chats)
      }
    },
    {
      name: 'newChat',
      handler: function onNewChat(result) {
        let { chat } = result
        const shouldSetActiveUser = result.activeUser
        if (shouldSetActiveUser) {
          return;
        }
        if (chat) { // If socket returns chat
          chats.addChats([chat])
          return;
        }
      }
    },
    {
      name: 'newMessage',
      handler: function onNewMessage(message) {
        const chatExists = chats.update(message.sender, message)
        if (!chatExists) {
          chatSocket.emit('indivChatRequested', { chattingWith: message.sender, activeUser: false })
        }
      }
    },
    {
      name: 'server_error',
      handler: function onServerError(error) {
        console.error(error)
        setChatError(error.message)
      }
    }
  ]

  useChatSocketEvents(events)

  const handleEnvelopeClick = () => {
    setShowNotifications(!showNotifications);
    setShowMessages(false);
  };

  const handleOutsideClick = () => {
    setShowNotifications(false);
  };

  const handleMessageClick = () => {
    setShowMessages(!showMessages);
    setShowNotifications(false);
  };

  const handleOutsideMessage = () => {
    setShowMessages(false);
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
  };

  function genNotifications() {
    if (!notifList) {
      return <Loading />
    }
    if (notifList.length === 0) {
      return <center><h5 style={{ color: 'black' }}>You have no new notifications</h5></center>
    }

    return notifList.map(e => <GetNotify
      key={e._id}
      notif={e}
    />)
  }

  function genChats() {
    if (!chats.sortedChats) {
      return <Loading />
    }
    if (chats.sortedChats.length === 0) {
      return <center><h5 style={{ color: 'black' }}>You have no new chats</h5></center>
    }

    return chats.sortedChats.slice(0, 5).map(e => <GetMessage key={e._id} chat={e} curUserId={curUserId} />)
  }

  useEffect(() => {
    if (!loggedIn) {
      setNotifList([])
      chats.reset()
      setChatError(undefined)
      return;
    }

    axios.get(`/notifications/${curUserId}?limit=5`)
      .then(result => setNotifList(result.data))
      .catch(error => {
        setNotifList([])
        console.error(error)
      })
    axios.get(`/users/view/${curUserId}`, { params: { justName: true, requester: curUserId }, withCredentials: true })
      .then(result => {
        setUserMeta(result.data)
      }).catch(error => {
        console.log(error)
      })

    chatSocket.emit('chatsPageRequested', { earliestTime: Date.now(), limit: chatDropdownLimit, unreadOnly: true })
  }, [loggedIn])

  useEffect(() => {
    if (location.pathname.toLowerCase() == '/chatpage') {
      // Removing chat that was clicked on from the list of new chats
      chats.remove(searchParams.get('id'))
    }
  }, [location, searchParams])

  useEffect(() => {
    // setShowNotifications(false);
    setShowMessages(false);
    setIsMenuOpen(false);
    setIsOpen(false);
  }, [location]);



  return (
    <>
      <div className="nav">
        {/* Responsive Menu Code */}

        <div className='ResponsiveMenu'>
          <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 20, paddingLeft: 20, justifyContent: 'space-between', paddingRight: '5%' }}>
            <Link to='Home' style={{ textDecoration: 'none' }}>
              <h2 style={{ margin: 0, textDecoration: 'none', color: '#34AED4', fontWeight: 700, fontStyle: 'italic' }}>Foras</h2>
            </Link>
            <a onClick={handleMenuToggle}>
              {isMenuOpen ? <FaTimes size={22} /> : <FaEllipsisH size={22} />}
            </a>
            {/* <div className='ImageContainer'> */}
            {/* </div> */}
          </div>
        </div>

        {isMenuOpen && (
          <div className={`DropMenu ${isMenuOpen ? 'open' : ''}`}>
            <div className='DropMenuWrapper'>
              <Link to='Home'>Home</Link>
              <Link to='Foras'>Foras</Link>
              <Link to='Freelancers'>Students - Graduates</Link>
              <Link to='About'>About</Link>
              <Link to='Notifications'>Notifications</Link>
              <Link to='ChatPage'>Messages</Link>
              <Link to='ContactUs'>Contact Us</Link>
              <Link to='Profile'>My Profile</Link>

              {!loggedIn ?
                <Link id='LoginSmallWrapper' to='Login'>Login</Link>
                :
                // <Link id='LoginSmallWrapper' to='Login'>Logout</Link>
                <button id='Logout' className='red' onClick={e => logout(e, navigate)}>Logout</button>

              }
            </div>
          </div>
        )}

        {/* Responsive Menu Code */}


        <div className="navwrapper">
          <div className="nav-logo">
            <Link to="/" style={{ textDecoration: 'none', margin: 0 }}>
              {/* <img src={SmallLogo} alt="Logo" /> */}
              <h2 id='LogoFont' style={{ margin: 0, textDecoration: 'none', color: '#34AED4', fontWeight: 700, fontStyle: 'normal' }}>FORAS</h2>
            </Link>
          </div>
          <h4 className="LetsCash">الحياة فرص</h4>

          <div className="navlinks">
            {/* <Link to={"/"} style={{ color: location.pathname === "/" ? "#34AED4" : "black" }}>Home</Link> */}
            <Link to="/Foras" style={{ color: location.pathname === "/Foras" ? "#34AED4" : "black" }}>Foras (Internships)</Link>
            <Link to="/Freelancers" style={{ color: location.pathname === "/Freelancers" ? "#34AED4" : "black" }}>Students & Graduates</Link>
            <Link to="/About" style={{ color: location.pathname === "/About" ? "#34AED4" : "black" }}>About</Link>
            <Link to="/ContactUs" style={{ color: location.pathname === "/ContactUs" ? "#34AED4" : "black" }}>Contact Us</Link>
            {!loggedIn ?
              <div> </div>

              :
              <a onClick={handleEnvelopeClick}>
                <FaRegBell color='black'
                  style={{ cursor: 'pointer' }}
                />
              </a>
            }
            {!loggedIn ?
              <div> </div>

              :
              <Link to="ChatPage">
                <FaEnvelopeOpenText color='black'
                  style={{ cursor: 'pointer' }}
                />
              </Link>
            }
          </div>

          <div className="navlinks2">

            {!loggedIn ?
              <div id='NotLogged' style={{ width: '95%' }}>
                <Link id='loginText' to="/Login" state={{ from: location.pathname + location.search }}>
                  Login
                </Link>
                <Link id='SignUpTextWrapper' to="/Login_SignUp" state={{ from: location.pathname + location.search }}>
                  <h3 id='SignUpText'>Sign Up</h3>
                </Link>
              </div>
              :
              <div id='NotLogged' style={styles.container}>
                <div style={styles.dropdown} onClick={toggleDropdown}>
                  <ProfileImage imageURL={userMeta?.imageURL} style={styles.profileImage} />
                  {/* <img
                    src="https://via.placeholder.com/30"
                    alt="Profile"
                    style={styles.profileImage}
                  /> */}
                  {/* <span style={styles.userName}>{curUserId}</span> */}
                  <span style={styles.userName}>{userMeta?.fullName}</span>
                  <span style={styles.caret}>{isOpen ? '▲' : '▼'}</span>
                </div>
                {isOpen && (
                  <div style={styles.menu}>
                    <Link to="/Profile" style={{ padding: 10, textDecoration: 'none', marginBottom: 25, color: 'black' }}>Profile</Link>

                    <a
                      href="#"
                      style={styles.menuItem}
                      onClick={(e) => logout(e, navigate)}
                    >
                      Logout
                    </a>
                  </div>
                )}
              </div>
            }
          </div>

          {/* 
  //FLOATING LINKS
          <div className='floatinglinks' style={{ backgroundColor: 'black' }}>
            <div style={{ marginRight: '10%' }}>
              <a className={`NavbarEnvelope ${showMessages ? 'whiteIcon' : ''}`} onClick={handleMessageClick}>
                <FaEnvelopeOpenText
                  style={{ cursor: 'pointer' }}
                />
              </a>
            </div>
            <div style={{ marginRight: '10%' }}>
              <a className={`RegBellEnvelope ${showNotifications ? 'whiteIcon' : ''}`} onClick={handleEnvelopeClick}>
                <FaRegBell
                  style={{ cursor: 'pointer' }}
                />
              </a>
            </div>
          </div> */}


          {/* Notifications */}
          {showNotifications && (
            <div
              style={{
                position: 'fixed',
                top: 62.5,
                right: '25%',
                width: '20%',
                marginTop: 0,
                backgroundColor: 'white',
                height: "60%",
                overflow: 'auto',
                overflowY: 'auto',
                zIndex: 9999,
                borderRadius: 20
              }}
              className="fadeIn"
              onBlur={handleOutsideMessage}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '5%', paddingRight: '5%' }}>
                <h4 onClick={() => { navigate('/Notifications'); setShowNotifications(false) }} style={{ cursor: 'pointer', color: 'black', fontSize: '1.5svh' }}>Notifications</h4>
                <h4 onClick={() => { navigate('/Notifications'); setShowNotifications(false) }} style={{ cursor: 'pointer', color: 'black', fontSize: '1.5svh' }}>View All</h4>
              </div>

              {genNotifications()}
            </div>
          )}
          {/* Notifications */}

          {/* Messages */}
          {showMessages && (
            <div
              style={{
                position: 'fixed',
                top: '20%',
                left: '10%',
                width: '30%',
                marginTop: 0,
                // backgroundColor: 'white',
                height: 400,
                overflow: 'auto',
                zIndex: 9999,
                borderRadius: 20
              }}
              className="fadeIn"
              onBlur={handleOutsideMessage}

            >
              <h4 style={{ textAlign: 'center' }}>Chats</h4>
              {genChats()}
            </div>
          )}
          {/* Messages */}
        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    position: 'relative',
    width: '95%',
    userSelect: 'none',
    paddingLeft: '20%'
  },
  dropdown: {
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    paddingTop: 5,
    paddingBottom: 5
  },
  profileImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '10px',
  },
  userName: {
    flexGrow: 1,
  },
  caret: {
    fontSize: 12,
    marginLeft: 10,
  },
  menu: {
    position: 'absolute',
    top: 40,
    left: 50,
    right: '0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#fff',
    zIndex: 1000,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    paddingTop: 20,
    paddingBottom: 20,
    width: '50%'
  },
  menuItem: {
    cursor: 'pointer',
    display: 'block',
    textDecoration: 'none',
    color: '#000',
    padding: 10,
    paddingTop: 20
  },
  menuItemHover: {
    backgroundColor: '#f0f0f0',
  },
  ProfileDropDown: {
    margin: 0,
    textDecoration: 'none',

  }
};
