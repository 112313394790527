import React, { useState, useEffect, useRef } from 'react'
import { Rating } from 'react-simple-star-rating'
import "./stylesheets/ApplyForas.css"
import ForasTag from './components/ForasTag'
import PdfLogo from './stylesheets/imgs/pdflogo.svg'
import PngLogo from './stylesheets/imgs/pnglogo.png'
import FileIcon from './stylesheets/imgs/file.png'
import axios from 'axios'
import { maxUserFileSize, validApplicationFileTypes } from '../config'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { calculateTimeAgo } from '../utils/JobUtils'
import Loading from './components/Loading'
import ProfileImage from './components/ProfileImage'
import AsyncSelect from 'react-select/async'
import { useUser } from '../hooks/useUser'
import { Modal } from 'react-bootstrap'
import DownloadLink from './components/DownloadLink'
import { FaUpload } from 'react-icons/fa';


export const SimilarTabs = ({ id, title, description, payment, owner, loading }) => {
    const navigate = useNavigate()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    let smallSize = windowWidth > 1024 ? 100 : 50;

    return (
        <div className='tab' id='centerdiv' style={{alignItems:'normal'}}>
            {loading ? <Loading /> :
                !id ? <></> :
                    <>
                        <div className='similarjobTitlte'>
                            <img id='similarjobTitlteimg' src={owner?.imageURL || "https://via.placeholder.com/30"} />
                            <div id='SimilarForasTop'>
                                <h4>{title}</h4>
                                <h5>{owner?.firstName} {owner?.lastName}</h5>
                            </div>
                        </div>
                        <div className='similarjobDesc'>
                            {/* This is where the dangerously html was placed */}
                            <h4 id='ThirdDesc'> {description.replace(/\\n/g, '').substring(0,smallSize) + '...'}</h4>
                        </div>
                        <div className='endingSimilarJob'>
                            <h4 id='jobPayment'>{payment}</h4>
                            <button id='SimilarJobApply' onClick={() => {
                                navigate(`/ApplyForas/${id}`)
                                // refresh
                                navigate(0)
                            }}>Apply {windowWidth > 768? "Now": ""}</button>
                        </div>
                    </>}
        </div>
    )
};


export default function ApplyForas() {
    // Job and page states
    const [job, setJob] = useState(undefined)
    const [owner, setOwner] = useState(undefined)
    const [similar, setSimilar] = useState([])
    const [viewerStatus, setViewerStatus] = useState()

    // Application states
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [filesToDelete, setFilesToDelete] = useState([])
    const [filesToUpload, setFilesToUpload] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([])
    const inputRef = useRef()
    const [bid, setBid] = useState("")
    const [appId, setAppId] = useState('')
    const [createStatus, setCreateStatus] = useState()
    const [isUpdating, setIsUpdating] = useState(false)

    // Invitation states
    const [invitationModalShown, setInvitationModalShown] = useState(false)
    const [invitations, setInvitations] = useState()

    // Other hooks
    const curUser = useUser()
    const navigate = useNavigate()
    const location = useLocation()
    const { id: jobId } = useParams()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // SIMILAR JOBS
    function genSimilarTabs() {
        const res = []
        for (let i = 0; i < 3; i++) {
            let sim = similar[i]
            let payment = sim?.isBid ? "Up for bid" : `${sim?.price}LE`
            res.push(
                <SimilarTabs
                    key={sim?._id}
                    id={sim?._id}
                    title={sim?.title}
                    description={sim?.description}
                    payment={payment}
                    owner={sim?.owner}
                    loading={!owner}
                />
            )
        }
        return res;

    }

    // FILE HANDLERS
    const handleFileDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setFilesToUpload(file);
    };

    const handleDragEnter = (event) => {
        event.preventDefault();
        // Add styling or effects to indicate the drop area
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        // Remove styling or effects from the drop area
    };

    const handleFileChange = (event) => {
        setFilesToUpload(event.target.files[0]);
    };

    function handleFileRemove(index) {
        const file = uploadedFiles[index]

        setFilesToDelete(prev => {
            if (!prev) prev = []

            const updated = [...prev]
            updated.push(file._id)
            return updated
        })

        setUploadedFiles(prev => {
            if (!prev) prev = []

            const updated = [...prev]
            updated.splice(index, 1)
            return updated
        })

    }

    // INVITATIONS
    async function viewInvitations() {
        setInvitationModalShown(true)

        if (!invitations) {
            axios.get(`/applications/invitations/${curUser.id}/${jobId}`)
                .then(result => {
                    setInvitations(result.data)
                })
        }
    }

    async function handleAcceptInvitation(index) {
        axios.put('applications/invitation/accept', { user: curUser.id, app: invitations[index]?._id })
            .then(result => {
                setInvitations(prev => {
                    const updated = [...prev]
                    if (updated[index]) {
                        const cur = updated[index].users.find(e => e.user._id === curUser.id)
                        if (cur) cur.accepted = true
                    }
                    return updated
                })
            })
            .catch(error => {
                console.log(error)
                alert(error.response?.data || error.message)
            })
    }

    async function handleRejectInvitation(index) {
        axios.delete('applications/invitation/reject', { data: { user: curUser.id, app: invitations[index]?._id } })
            .then(result => {
                setInvitations(prev => {
                    const updated = [...prev]
                    updated.splice(index, 1)
                    return updated
                })
            })
            .catch(error => {
                console.log(error)
                alert(error.message)
            })
    }

    // MISCELLANEOUS
    async function handleUsernameFilter(text) {
        // Handling user typing, don't want to fire a request with every keypress, so I set a timeout before search is fired
        try {
            inputRef.current = text
            const promise = new Promise((resolve, reject) => setTimeout(() => {
                if (inputRef.current === text) {
                    resolve()
                    return;
                }
                reject();
            }, 400))

            await promise;
        } catch (error) {
            return;
        }

        // Sending search request
        return axios.get(`/users/findByUsername/${text}`, { params: { user: curUser.id } })
            .then(result => result.data.map(e => ({ value: e._id, label: e.username })))
            .catch(error => {
                console.error(error);
            })
    }

    async function handleApply() {
        if (!curUser.loggedIn) {
            navigate('/Login', { state: { from: location.pathname + location.search } })
            return;
        }
        
        if (job.isBid && !bid?.trim()) {
            alert("Bid cannot be empty")
            return;
        }

        if (filesToUpload) {
            if (filesToUpload.size > maxUserFileSize) {
                alert(`File too large. Max size is ${maxUserFileSize / (1024 * 1024)}MB`);
                return;
            }

            // Validating file type
            if (!validApplicationFileTypes.split(',').includes(filesToUpload.type)) {
                alert("Invalid file type. Allowed types are: " + validApplicationFileTypes);
                return;
            }
        }

        setCreateStatus(isUpdating ? 'updating' : 'creating')
        try {
            // Creating application
            const application = await axios.put('/applications/create', {
                job: jobId,
                users: [curUser.id, ...(selectedUsers.map(e => e.value))],
                user: curUser.id,
                bid
            }).then(result => {
                setIsUpdating(true)
                return result
            })

            const proms = []
            if (filesToDelete?.length > 0) {
                setCreateStatus("uploading")

                proms.push(
                    axios.delete('/applications/files/deleteMany', {
                        data: {
                            app: appId,
                            user: curUser.id,
                            files: filesToDelete
                        }
                    })
                )
            }

            if (filesToUpload) {
                setCreateStatus("uploading")
                // Creating form data
                const formData = new FormData()
                formData.append('user', curUser.id)
                formData.append('file', filesToUpload)
                formData.append('app', application.data._id)
                proms.push(
                    axios.post('/applications/files/upload',
                        formData,
                        { onUploadProgress: e => console.log(e) }
                    ).then(result => {
                        setUploadedFiles(prev => {
                            const updated = [...prev]
                            updated.push(filesToUpload)
                            setFilesToUpload(null)
                            return updated
                        })
                    })
                )
            }

            await Promise.all(proms)
            setCreateStatus(null)
        } catch (error) {
            alert(error.response?.data || error.message)
            console.error(error)
            setCreateStatus(null)
        }
    }

    async function fetchData() {
        var result;
        try {
            result = await axios.get(`/jobs/view/${jobId}`, {
                params: {
                    getOwner: true,
                    getSimilar: true,
                    getUserStatus: true,
                    getApp: true,
                    getAppFiles: true,
                    user: curUser.id
                }
            })
            setJob(result.data.job)
            setOwner(result.data.owner)
            setSimilar(result.data.similar)
            setViewerStatus(result.data.viewerStatus)

            // If this user has created an application for this job
            if (result.data.app) {
                setIsUpdating(true)
                setAppId(result.data.app._id)
                setBid(result.data.app.bid?.toString() || '')
                setSelectedUsers(
                    result.data.app.users?.filter(e => e.user._id !== curUser.id)
                        .map(e => ({ value: e.user._id, label: e.user.username })) || [])

                if (result.data.app.files) {
                    setUploadedFiles([...result.data.app.files])
                }
            } else {
                setIsUpdating(false)
            }
        } catch (error) {
            console.log(error)
            alert(error.response.data)
        }
    }

    useEffect(() => {
        fetchData()
    }, [curUser.loggedIn, jobId])

    useEffect(() => {
        if(!job) return;

        if(['progress', 'review', 'complete'].includes(job.status)){
            navigate(`/ActiveForsa/${jobId}`)
        }
    }, [job])

    return (
        <div className='ApplyForasPage'>
            <div className='ForsaDetails'>
                <div className='leftDetails'>
                    {
                        owner ?
                            <>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 0 }}>
                                    <h2 id='jobtitleNew' style={{ margin: 0, fontStyle: 'italic', marginBottom: '2.5%', fontWeight: 500 }}>{job.title}</h2>
                                    {(!curUser.loggedIn || curUser.isRole('employee')) && <button id='ApplyNowButton' disabled={createStatus} onClick={handleApply}>{isUpdating ? 'Update' : 'Apply Now'}</button>}
                                </div>
                                <div className='leftProfile'>
                                    <ProfileImage id={owner._id} imageURL={owner.imageURL} />
                                    <div className='ProfileDetails' style={{ width: windowWidth > 1024 ? '30%': '60%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Link to={`/Profile?userID=${owner._id}`}><h4 style={{ margin: 0, fontWeight: 600, fontSize: '1.3svh' }} > {owner.fullName}</h4></Link>
                                            <h4 style={{ margin: 0, fontWeight: 400, fontSize: '1.3svh', color: '#00000050', userSelect: 'none', fontStyle: 'italic' }} >Cairo, Egypt</h4>
                                            <h4 style={{ margin: 0, fontWeight: 400, fontSize: '1.3svh', color: '#00000050', userSelect: 'none', fontStyle: 'italic' }} >Posted {calculateTimeAgo(job.createdAt)}</h4>
                                        </div>
                                        {/* <img src = {RatingGround}/> */}
                                        {/* <Rating initialValue={owner.rating?.average} readonly={true} allowFraction={true} size={'20'} /> */}
                                        {job ?
                                            <div id='displayApplyTag'>
                                                {
                                                    job.tags?.slice(0, 3).map((tag, i) =>
                                                        <div key={i} id="ApplyForasTag">
                                                            <h3>{tag}</h3>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            :
                                            <>
                                                <Loading />
                                            </>
                                        }
                                    </div>
                                </div>
                                {/* <div className='moreDetails'>
                                    <h4 style={{padding:0, margin:0}}>{owner.jobCount || 0} For{owner.jobCount === 1 ? "sa" : "as"} Created</h4>
                                </div> */}
                                {/* <Link to={`/Profile?userID=${owner._id}`}><h4>Other Foras by {owner.fullName?.split(' ')[0]}</h4></Link> */}
                            </>
                            : <Loading />
                    }
                </div>
                <div className='rightDetails'>
                    {job ?
                        <>

                            <div className='PropertyTags'>
                                {/* <div className='SecondTag'>
                                    <div className='duration'>
                                        <h4>Posted {calculateTimeAgo(job.createdAt)}</h4>
                                    </div>
                                </div> */}

                                <div className='ThirdTag'>
                                    <div style={{ marginBottom: '2.5%' }}>
                                        <h4 id='AboutThisRole'>About this role</h4>
                                        {/* This is where the dangerously html was placed */}
                                        <h4 id='ThirdDesc'> {job.description.replace(/\\n/g, '')}</h4>
                                        </div>
                                    {/* <div style={{ marginBottom: '5%' }}>
                                        <h4 id='AboutThisRole'>Qualifications</h4>
                                        <h4 id='ThirdDesc'>Placeholder text until the placeholder text no longer becomes a placeholder text</h4>
                                    </div> */}
                                </div>
                                <div style={{ marginTop: '25%' }}>
                                    <h3 style={{ padding: 0, margin: 0, color: '#00000050', fontSize: '1.25svh', marginBottom: '1%', fontStyle: 'italic', fontWeight: 300 }}>Uploaded Files:</h3>
                                    {uploadedFiles.map((e, i) => {
                                        return <div key={e._id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 10 }}>
                                            <DownloadLink
                                                downloadName={e.name}
                                                requestBody={{ user: curUser.id, file: e._id, app: appId }}
                                                url={'applications/files/download'}
                                            >
                                                <h4>{e.name}</h4>
                                                {/* TODO: Display download progress here */}
                                            </DownloadLink>
                                            <button style={{}} onClick={() => handleFileRemove(i)}>Remove</button>
                                        </div>
                                    })}
                                </div>
                                <div
                                    className='dropArea'
                                    onDrop={handleFileDrop}
                                    onDragOver={(event) => event.preventDefault()}
                                    onDragEnter={handleDragEnter}
                                    onDragLeave={handleDragLeave}
                                >
                                    {filesToUpload ? (
                                        <div className='fileInfo'>
                                            {filesToUpload.name.endsWith('.pdf') ? (
                                                <img src={PdfLogo} alt='PDF Logo' style={{ width: 20 }} />
                                            ) : filesToUpload.name.endsWith('.png') ? (
                                                <img src={PngLogo} alt='PNG Logo' style={{ width: 20 }} />
                                            ) : (
                                                <img src={FileIcon} alt='File Icon' style={{ width: 20 }} />
                                            )}
                                            <p style={{ fontSize: 10 }}>{filesToUpload.name}</p>
                                        </div>
                                    ) : (
                                        <p>Drag and drop a file here or click to upload.</p>
                                    )}
                                </div>

                                {/* <input id='choosefile' type='file' onChange={handleFileChange} /> */}
                                <div className="file-input-container">
                                    <input id="choosefile" type="file" onChange={handleFileChange} />
                                    <FaUpload className="file-input-icon" />
                                </div>
                                {/* <p>Invite other freelancers to join your team!</p>
                                <AsyncSelect
                                    placeholder="Username..."
                                    defaultValue={""}
                                    loadOptions={handleUsernameFilter}
                                    isMulti
                                    value={selectedUsers}
                                    onChange={setSelectedUsers}
                                /> */}
                                {/* <div className='FourthTag'>
                                    <div className='submittedpeople'>
                                        <h4>{job.applicantCount || 0} Applicant{job.applicantCount === 1 ? "" : "s"}</h4>
                                    </div>
                                    {viewerStatus?.startsWith("applied") && <button id='ViewInvitationsButton' onClick={viewInvitations}>View Invitations</button>}
                                </div> */}

                            </div>
                            <Modal
                                id = 'popUpModal'
                                show={invitationModalShown}
                                style={{ }}
                            >
                                <div id='userRow' style={{justifyContent:'center'}}>
                                    <h4 style={{}}>Invitations</h4>
                                </div>
                                {!invitations ?
                                    <Loading /> :
                                    invitations.map((e, index) => {
                                        let curHasAccepted = false
                                        return <div key={e._id} id='userRow'>
                                            {e?.users?.map(u => {
                                                if (u.user._id === curUser.id && u.accepted) {
                                                    curHasAccepted = true
                                                }
                                                return <Link id='LinkuserRow' key={u.user._id} to={`/Profile/?userID=${u.user._id}`} target="_blank">
                                                    <h4 style={{ color: u.accepted ? 'black' : '#34AED4', marginBlock: 0 }}>
                                                    {u.user._id === curUser.id ? "" : "Join @"}{u.user.username} {u.user._id === curUser.id ? "(You)" : ""}
                                                    </h4>
                                                </Link>
                                            })}
                                            <button id='buttonModal' style={{backgroundColor:'rgba(255, 0, 0, 0.33)'}} onClick={() => handleRejectInvitation(index)}>{'Reject'}</button>
                                            {!curHasAccepted && <button id='buttonModal' onClick={() => handleAcceptInvitation(index)}>Accept</button>}
                                        </div>
                                    })
                                }
                                <button id='buttonModal' style={{marginTop:'2.5%'}} onClick={() => setInvitationModalShown(false)}>Close</button>
                            </Modal>
                            <Modal
                                show={createStatus && createStatus !== 'success'}
                                style={{ width: '50%', height: '50%', backgroundColor: 'white', position: 'fixed', top: '25%', left: '25%' }}
                            >
                                <Modal.Body >
                                    <Loading />
                                    <p>{createStatus}</p>
                                </Modal.Body>
                            </Modal>
                        </>
                        : <Loading />}
                </div>
            </div>

            <div className='SimilarForasSection'>
                <h3 style={{ textAlign: 'left', padding: '5%', fontStyle: 'italic' }}>Similar Foras</h3>
                <div className='SimilarForas'>
                    {genSimilarTabs()}

                </div>
            </div>
        </div>
    )
}

