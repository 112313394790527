import { React, useMemo, useState, useEffect } from 'react';
import "./stylesheets/Freelancer.css";
import ForasTag from './components/ForasTag';
import ProfileFreelancer from './stylesheets/imgs/ProfileFreelancer.png';
import axios from 'axios';
import ProfileImage from './components/ProfileImage.js';
import { Buffer } from 'buffer';
import { useUser } from '../hooks/useUser.js';
import Loading from './components/Loading.js';
import { Link } from 'react-router-dom';
import { FaSearch } from "react-icons/fa"

const FreelancerProfile = ({ user }) => {
    const getSpecializationText = () => {
        const tags = user.tags || [];
        if (tags.length === 0) {
            return "No Specializations";
        } else if (tags.length === 1) {
            return `Specializes in ${tags[0]}`;
        } else if (tags.length === 2) {
            return `Specializes in ${tags[0]} and ${tags[1]}`;
        } else {
            return `Specializes in ${tags[0]}, ${tags[1]}, and ${tags[2]}`;
        }
    };

    return (
        <Link className='FreeProfile' to={`/Profile?userID=${user._id}`} style={{ textDecoration: 'none' }}>
            <Link className='ProfileImg' to={`/Profile?userID=${user._id}`} style={{ textDecoration: 'none', color: 'black' }}>
                <ProfileImage id={user._id} imageURL={user.imageURL} />
            </Link>

            <div>
                <h5 id='FreeProfileName'>{user.firstName} {user.lastName}</h5>
                <h4 id='FreeProfileSpecial'>
                    {getSpecializationText()}
                </h4>
                <h5 id='bioFreelancer'>"{user.bio}"</h5>
            </div>
        </Link>
    );
};

export default function Freelancers() {
    const curUser = useUser();

    const [activeTags, setActiveTags] = useState([]);
    const [params, setParams] = useState({ user: curUser.id });
    const [categories, setCategories] = useState([]);
    const [freelancers, setFreelancers] = useState();
    const [loading, setLoading] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        fetchOptions();
        performSearch();
    }, []);

    const selectedCategoryTags = useMemo(() => {
        if (!params.category) return [];
        return categories.find((option) => option._id === params.category)?.tags || [];
    }, [params.category]);

    const fetchOptions = () => {
        if (categories.length > 0) {
            return;
        }
        axios.get(`/categories/`).then(result => {
            setCategories(result.data?.categories);
        }).catch(error => {
            console.log(error);
            setCategories([]);
        });
    };

    const generateOptionList = () => {
        return categories.map((option) => (
            <label key={option._id} className="radio-label" style={{ marginBottom: '5%' }}>
                <input
                    type="radio"
                    name="category"
                    value={option._id}
                    checked={params.category === option._id}
                    onChange={handleOptionChange}
                />
                {option.name}
            </label>
        ));
    };

    const handleOptionChange = (event) => {
        setParams(prev => ({ ...prev, category: event.target.value }));
        setActiveTags([]);
    };

    const generateTags = () => {
        if (selectedCategoryTags.length === 0) {
            return Array.from({ length: 5 }).map((_, index) => (
                <div key={index} style={{ display: 'flex', marginBottom: '10%' }}>
                    <label style={{ width: '100%', fontSize: '1.75svh', textWrap: 'nowrap', width: '70svh', display: 'flex', alignItems: 'center', color:'#00000050' }}>
                        <input
                            style={{ margin: 0, padding: 0, marginRight: '5%' }}
                            type="checkbox"
                            disabled
                        />
                        Choose Category
                    </label>
                </div>
            ));
        }

        return selectedCategoryTags.map((button, index) => (
            <div key={index} style={{ display: 'flex', marginBottom: '10%' }}>
                <label style={{ width: '100%', fontSize: '1.75svh', textWrap: 'nowrap', width: '70svh', display: 'flex', alignItems: 'center' }}>
                    <input
                        style={{ margin: 0, padding: 0, marginRight: '5%' }}
                        type="checkbox"
                        checked={activeTags.includes(index)}
                        onChange={() => handleClickTag(index)}
                    />
                    {button}
                </label>
            </div>
        ));
    };


    const handleClickTag = (index) => {
        if (activeTags.includes(index)) {
            setActiveTags(activeTags.filter((item) => item !== index));
        } else {
            setActiveTags([...activeTags, index]);
        }
    };

    const renderResultsSpace = () => {
        if (loading) return <Loading />;

        if (!freelancers) {
            return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100svh', width: '100%', paddingBottom: '10%' }}>
                <h3 style={{ color: '#00000060', fontWeight: 300, marginBottom: '1%' }}>Start searching to find your ideal Freelancer!</h3>
                <FaSearch size={20} color='#00000020' />
            </div>
        }
        if (freelancers.length === 0) {
            return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '30svh', width: '100%', paddingBottom: '10%' }}>
                <h3 style={{ color: '#00000060', fontWeight: 300, marginBottom: '1%' }}>No freelancers found in this query</h3>
                <FaSearch size={20} color='#00000020' />
            </div>
        }

        return freelancers.map(e => <FreelancerProfile user={e} />);
    };

    function performSearch(event) {
        if (event) event.preventDefault();
        setLoading(true);

        let tagArr;
        if (activeTags.length > 0) {
            tagArr = Buffer.from(JSON.stringify(activeTags.map(e => selectedCategoryTags[e])))
                .toString('base64')
                .replace(/[+/=]/g, function (match) {
                    return match === '+' ? '-' : match === '/' ? '_' : '';
                });
        }

        axios.get('/users/searchFreelancers', { params: { ...params, tags: tagArr } })
            .then(result => {
                setFreelancers(result.data);
            })
            .catch(error => {
                console.log(error);
                alert(error.response?.data || error.message);
            })
            .finally(() => setLoading(false));
    }

    const handleReset = () => {
        setParams({ user: curUser.id });
        setActiveTags([]);
        document.getElementById('FreelancerFilterSearch').value = '';
    };

    return (
        <div className='ForasPage' style={{}}>
            <div className='SearchForsa' id='SearchFreelancer'>
                {windowWidth > 768?
                    <div id='FilterArea'>
                    <h4 style={{ color: 'black', fontSize: '1.5svh' }}>Filters</h4>
                    <a onClick={handleReset} style={{ color: 'black', fontSize: '1.5svh', cursor: 'pointer' }}>Reset</a>
                </div>
                :
                ''
                }
                <input id='search' type="search" value={params.query} onChange={e => setParams(prev => ({ ...prev, query: e.target.value }))} placeholder=' Search' />
                <form onSubmit={performSearch} id='generateOptions'>
                    <h4 style={{ fontWeight: 'bold', color: 'black' }}>Job Category</h4>
                    <form style={{ display: 'flex', flexDirection: 'column', width: '100%' }} id='generateOptions' onFocus={fetchOptions}>
                        {generateOptionList()}
                    </form>
                    {/* <div id='CategoryArea' style={{marginBottom:'2.5%'}}>
                        <h4 style={{ fontWeight: 'bold', color: 'black'}}>Job Tags</h4>
                        <div className='displayTopicsSection'>
                            {generateTags()}
                        </div>
                    </div> */}
                    <button type='submit' style={{ width: '80%', borderRadius: 5, padding: 10, alignSelf: 'center', backgroundColor: '#34AED4', borderStyle: 'none', cursor: 'pointer', fontWeight: 600, fontSize: 16, color: 'white' }}>Filter</button>
                </form>
            </div>

            <div className="JobDisplaySection">
                <div className='rightSide'>
                    {renderResultsSpace()}
                </div>
            </div>
        </div>
    );
}
